.MuiDataGrid-row {
    .MuiDataGrid-IconButton{
        display: none
    }

    .bulk-company-name {
        .MuiCheckbox-root{
            visibility: hidden;
        }
    }
}

.MuiDataGrid-row:hover {
    .MuiDataGrid-IconButton{
        display: block
    }
    .bulk-company-name {
        .MuiCheckbox-root{
            visibility: visible;
        }
    }
}
