.loader-component {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #39397b;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
